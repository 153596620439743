<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.day_off") }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <select-multiple
            v-model="item.pos_ids"
            :options="posOptions"
            :label="$t('labels.pos')"
            custom-class="c-input-small"
          ></select-multiple>
        </v-col>
        <v-col cols="12">
          <date-picker-multiple-dialog
            v-model="item.dates"
            :label="$t('labels.day_off')"
            :date-picker-props="datePickerProps"
          ></date-picker-multiple-dialog>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.note"
            :label="$t('labels.note')"
            type="text"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn color="green darken-1" text @click="createWorkingTime">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";

export default {
  name: "DayOffCreate",
  components: {},
  data: () => ({
    posOptions: [],
    item: {
      pos_ids: [],
      dates: [],
      note: null,
      status: 1,
    },
    isLoading: false,
    datePickerProps: {
      min: moment().format("YYYY-MM-DD"),
    },
  }),
  mounted() {
    this.getPos();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    onTimeChange(time) {
      this.item = { ...this.item, start: time };
    },
    async getPos() {
      if (this.idPos) {
        return false;
      }
      const { data } = await httpClient.post("/pos-all-active");
      this.posOptions = data.map((e) => ({
        value: e.id,
        text: e.code,
      }));
    },
    async createWorkingTime() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-create-day-off", this.item);
        this.isLoading = false;
        const msg = this.item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.refreshData();
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
